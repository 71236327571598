import {
  Avatar,
  BackgroundModal,
  CloseButton,
  Container,
  ContentModal,
  FormCol,
  FormField,
  FormRow,
  FormStep,
  Header,
  Label,
  ListboxWrapper,
  Subtitle,
  Title,
} from './styles'
import ImgClose from '../../../../../Home/assets/icon-close.svg'
import { useForm } from 'react-hook-form'
import { Listbox } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { parametrosRepository } from '../../../../../../api/repositories/parametrosRepository'
import { Checkbox } from '@mui/material'
import ImgIconUser from '../../../../../Home/assets/icon-usr-register-modal.svg'
import { veiculoRepository } from '../../../../../../api/repositories/veiculosRepository'
import { Alerts } from '../../../../../../utils/alert'

const DEFAULT_VALUES = {
  descricaoFiliacao: '',
  porcentagemMaxima: 0,
  vendedores: [],
  veiId: {},
}

export function ModalFiliacao({
  openModal,
  setOpenModal,
  filiacaoId,
  alteracaoFeita,
  setLoading,
}) {
  const [vendedores, setVendedores] = useState([])
  const [selectedVendedores, setSelectedVendedores] = useState([])
  const [query, setQuery] = useState('')
  const [filiacaoDados, setFiliacaoDados] = useState(DEFAULT_VALUES)
  const [tiposVeiculo, setTiposVeiculo] = useState([])
  const [selectedVeiculo, setSelectedVeiculo] = useState('')

  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: DEFAULT_VALUES,
  })

  useEffect(() => {
    setLoading(true)
    if (filiacaoId) {
      buscarTabelaFiliacao()
    }
    buscarVendedores()
    buscarTiposVeiculos()
  }, [filiacaoId])

  useEffect(() => {
    if (filiacaoId && filiacaoDados) {
      reset(filiacaoDados)

      setSelectedVendedores(filiacaoDados.vendedores)
      setSelectedVeiculo(filiacaoDados.veiId)
      setValue('porcentagemMaxima', filiacaoDados.porcentagemMaxima)
    }
  }, [filiacaoDados, vendedores, reset, setValue])

  async function onSubmit(dataSubmitting) {
    const FIL_NOME = dataSubmitting.descricaoFiliacao
    const FIL_PORCENTAGEM = Number(dataSubmitting.porcetagemMaxima)
    const FIL_VEN_IDS = selectedVendedores
    const FIL_TIPO_VEICULO = dataSubmitting.veiId
    const FIL_ID = filiacaoId

    if (filiacaoId) {
      const editFiliacaoResponse = {
        FIL_ID,
        FIL_NOME,
        FIL_PORCENTAGEM,
        FIL_VEN_IDS,
        FIL_TIPO_VEICULO,
      }

      await editarFiliacao(editFiliacaoResponse)
    } else {
      const criarFiliacaoResponse = {
        FIL_NOME,
        FIL_PORCENTAGEM,
        FIL_VEN_IDS,
        FIL_TIPO_VEICULO,
      }

      await criarFiliacao(criarFiliacaoResponse)
    }

    alteracaoFeita(true)
  }

  async function buscarVendedores() {
    try {
      const vendedores = await parametrosRepository.buscarVendedores()
      setVendedores(vendedores)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  async function criarFiliacao(filiacaoDados) {
    try {
      const data = await parametrosRepository.criarTabelaFiliacao(filiacaoDados)

      if (data.error) {
        Alerts.ERROR(data.message || 'Erro inesperado ao editar cotação!')
        return
      }

      Alerts.SUCCESS('Filição criada com sucesso!')

      setOpenModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function editarFiliacao(filiacaoDados) {
    try {
      const data =
        await parametrosRepository.editarTabelaFiliacao(filiacaoDados)

      if (data.error) {
        Alerts.ERROR(data.message || 'Erro inesperado ao editar cotação!')
        return
      }

      Alerts.SUCCESS('Filição editada com sucesso!')

      setOpenModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function buscarTabelaFiliacao() {
    try {
      const data =
        await parametrosRepository.buscarDadosTabelaFiliacao(filiacaoId)
      setFiliacaoDados({
        descricaoFiliacao: data?.dados[0].FIL_NOME,
        porcetagemMaxima: Number(data?.dados[0].FIL_PORCENTAGEM),
        vendedores: data?.dados[0].FIL_VEN_IDS,
        veiId: data?.dados[0].FIL_TIPO_VEICULO,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function buscarTiposVeiculos() {
    try {
      const { tipos } = await veiculoRepository.buscarTiposVeiculos()
      setTiposVeiculo(tipos)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  function handleSelectVendedor(vendedor) {
    setSelectedVendedores(vendedor)
    setValue('vendedorId', vendedor)
  }

  function handleSelectVeiculo(veiculo) {
    setSelectedVeiculo(veiculo)
    setValue('veiId', veiculo)
  }

  const filtrarVendedores =
    query === ''
      ? vendedores
      : vendedores.filter((vendedor) => {
          return vendedor.label.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <CloseButton onClick={() => setOpenModal(false)}>
                <img src={ImgClose} alt="Fechar modal" />
              </CloseButton>
              <div>
                <Title>
                  {filiacaoId
                    ? 'EDITAR DESCONTO FILIAÇÃO'
                    : 'NOVO DESCONTO FILIAÇÃO'}
                </Title>
              </div>
            </Header>
            <main>
              <FormStep>
                <FormRow>
                  <FormField>
                    <Label>Descrição</Label>
                    <input
                      type="text"
                      {...register('descricaoFiliacao')}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                    />
                  </FormField>
                </FormRow>
                <FormField>
                  <Label>Vendedor</Label>
                  <ListboxWrapper>
                    <Listbox
                      value={selectedVendedores}
                      onChange={handleSelectVendedor}
                      multiple
                    >
                      <>
                        <div style={{ display: 'flex' }}>
                          <Listbox.Button className="ListboxButton">
                            {selectedVendedores.length === 0
                              ? 'Selecione o Vendedor'
                              : selectedVendedores
                                  .map((vendedor) => vendedor.label)
                                  .join(', ')}
                          </Listbox.Button>
                        </div>

                        {
                          <Listbox.Options className="combobox-options">
                            <div className="combobox-search">
                              <input
                                type="text"
                                placeholder="Pesquisar..."
                                value={query}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                            </div>

                            <div
                              className="combobox-option"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onClick={() => {
                                selectedVendedores.length === vendedores.length
                                  ? setSelectedVendedores([])
                                  : setSelectedVendedores(vendedores)
                              }}
                            >
                              <Checkbox
                                checked={
                                  selectedVendedores.length ===
                                  vendedores.length
                                }
                                onClick={(event) => {
                                  event.target.checked
                                    ? setSelectedVendedores(vendedores)
                                    : setSelectedVendedores([])
                                }}
                              />
                              <span
                                style={{
                                  fontSize: '12px',
                                }}
                              >
                                Selecionar todos
                              </span>
                            </div>
                            {filtrarVendedores.map((vendedor) => (
                              <Listbox.Option
                                value={vendedor}
                                className="combobox-option"
                              >
                                {({ selected }) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Checkbox checked={selected} />
                                    <span style={{ fontSize: '12px' }}>
                                      {vendedor.label}
                                    </span>
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        }
                      </>
                    </Listbox>
                  </ListboxWrapper>
                </FormField>
                <FormCol>
                  <FormField>
                    <Label>Porcentagem Máxima</Label>
                    <input type="number" {...register('porcetagemMaxima')} />
                  </FormField>
                  <FormField>
                    <Label>Tipo de Véiculo</Label>
                    <input
                      type="hidden"
                      {...register('veiId')}
                      value={selectedVeiculo}
                    />
                    <ListboxWrapper>
                      <Listbox
                        value={selectedVeiculo}
                        onChange={handleSelectVeiculo}
                      >
                        <Listbox.Button className="ListboxButton">
                          {selectedVeiculo === ''
                            ? 'Selecione...'
                            : selectedVeiculo.label}
                        </Listbox.Button>

                        <Listbox.Options className="ListboxOptions">
                          {tiposVeiculo.map((veiculo) => (
                            <Listbox.Option
                              value={veiculo}
                              key={veiculo.value}
                              className="ListboxOption"
                            >
                              {veiculo.label}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </ListboxWrapper>
                  </FormField>
                </FormCol>
              </FormStep>
              <div className="save_button">
                <button type="submit">Salvar</button>
              </div>
            </main>
          </form>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
