import { Tab } from '@headlessui/react'
import styled from 'styled-components'

export const SettingsContainer = styled.main`
  display: grid;
  grid-template-columns: 3fr 9fr;

  height: 89vh;
`

export const SettingsContainerAside = styled.aside`
  border-right: 0.2px solid #e2e2e2;

  margin-top: 1rem;
  /* width: 330px; */
  padding-top: 1.75rem;
  padding-left: 4.1875rem;
  padding-right: 1rem;

  /* @media (min-width: 1500px) {
    width: 436px;
  } */

  h1 {
    font-size: 2.5rem;
  }
`

export const SettingsSubMenu = styled(Tab.List)`
  margin-top: 2rem;
  height: 65.5vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  span {
    font-size: 0.9375rem;
    color: #838387;
    line-height: 2rem;
    padding-left: 0.5rem;
  }

  li {
    button {
      background: none;
      border: none;
      padding: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 8px;
      font-weight: 500;
      cursor: pointer;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 17px;

      img,
      svg {
        margin-right: 0.5rem;
      }

      :not(.isActive):hover {
        background-color: #f2f2f2;

        fill: #f2f2f2;
      }

      :focus {
        outline: none;
      }
    }

    .isActive {
      background-color: #c8ddff;

      color: #0049c4;
      fill: #0049c4;

      font-weight: bold;
    }
  }
`

export const SubMenuConfigSystem = styled.div`
  display: flex;
  flex-direction: column;

  padding: 3rem 3rem 0 3rem;

  > h3 {
    margin: 3.125rem 0 1rem 0;
  }

  .SettingUsers__Header {
    display: grid;
    grid-template-columns: 4fr 2fr;
    justify-content: space-between;
  }

  .SideAdd {
    .SideAdd__Title {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      font-size: 1.875rem;
      font-weight: bold;
    }

    .SideAdd__Subtitle {
      line-height: 2rem;
    }
  }
  .oportunidades_geradas {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 16px;
  }

  .oportunidades {
    width: 430px;
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .oportunidades_menu {
    background-color: #f2f2f2;
    border-radius: 8px;

    padding: 1rem 0.5rem;
    width: 430px;
    box-sizing: border-box;

    label {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.00938em;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    }

    input {
      border: none;
      width: 25px;
      background: none;
      border-bottom: 2px solid #ccc;
      text-align: center;

      :focus {
        outline: none;
      }
    }

    span {
      font-size: 0.925rem;
      color: #dc1c4c;
      font-weight: bold;
    }
  }

  .impressao_oportunidades_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .impressao_config {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 430px;
      padding: 0.5rem;
      background-color: #f2f2f2;
      border-radius: 8px;

      box-sizing: border-box;

      > div {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      svg {
        color: #666666;

        :hover {
          cursor: pointer;
          color: #474747;
        }
      }

      .input-file {
        display: none;
      }

      .file-upload-label {
        display: flex;
        align-items: center;
        padding: 10px 10px;
        background-color: #c8ddff; /* Cor de fundo do botão */
        color: #0049c4; /* Cor do texto */
        border: none; /* Remove a borda */
        border-radius: 6px; /* Cantos arredondados */
        cursor: pointer; /* Aponta que é clicável */
        text-align: left;
        width: 170px;
        box-sizing: border-box;

        span {
          width: 150px; /* Largura fixa */
          overflow: hidden; /* Esconde o excesso de texto */
          white-space: nowrap; /* Impede que o texto quebre em múltiplas linhas */
          text-overflow: ellipsis; /* Adiciona "..." ao final do texto quando cortado */
          box-sizing: border-box;
        }
      }
    }

    .save_button {
      display: flex;
      justify-content: center;

      button {
        width: 7.5rem;
        height: 1.5625rem;
        margin-top: 1.25rem;
        border: none;
        border-radius: 5px;
        background-color: #00b065;
        color: #ffffff;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;

        :disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
`
