import { useEffect, useState } from 'react'
import ImgAdd from '../../../../Home/assets/icon-add.svg'
import menuAjustesTable from '../../../../Home/assets/menu-ajustes-table.svg'
import { FiliacaoContainer } from './styles'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import editPencil from '../../../../Home/assets/icon-edit-pencil.svg'
import archiveIcon from '../../../../Home/assets/icon-arquivar.svg'
import { ModalFiliacao } from './ModalFiliacao/ModalFiliacao'
import { ModalArchive } from './ModalArchive/ModalArchive'

export function Filiacao({ setLoading }) {
  const [filterResult, setFilterResult] = useState([])
  const [dataList, setDataList] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [alteracaoFeita, setAlteracaoFeita] = useState(false)
  const [modalFiliacao, setModalFiliacao] = useState(false)
  const [filiacaoData, setFiliacaoData] = useState([])

  const [filId, setFilId] = useState(null)

  useEffect(() => {
    setLoading(true)
    buscarTabelasFiliacao()
    setAlteracaoFeita(false)
  }, [alteracaoFeita])

  async function buscarTabelasFiliacao() {
    try {
      const data = await parametrosRepository.listarTabelasFiliacao()
      setDataList(data?.dados)
      setFilterResult(data?.dados)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = dataList.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })

    setFilterResult(filteredData)
  }

  function ButtonDropDownMenu({ filiacao }) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '5rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!filiacao.FIL_ATIVO}
              onClick={() => {
                setFilId(filiacao.FIL_ID)
                setOpenModal(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setModalFiliacao(true)
                setFiliacaoData(filiacao)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>{filiacao.FIL_ATIVO ? 'Arquivar' : 'Desarquivar'}</span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <FiliacaoContainer>
      <>
        {openModal && (
          <ModalFiliacao
            openModal={openModal}
            setOpenModal={setOpenModal}
            setLoading={setLoading}
            alteracaoFeita={setAlteracaoFeita}
            filiacaoId={filId}
          />
        )}

        {modalFiliacao && (
          <ModalArchive
            openModal={modalFiliacao}
            setOpenModal={setModalFiliacao}
            alteracaoFeita={setAlteracaoFeita}
            filiacaoData={filiacaoData}
          />
        )}

        <section>
          <header>
            <div>
              <div className="SettingsUsers_Header_Title">
                <span className="SettingsUsers_Header_Title">Filiação</span>
                <img
                  src={ImgAdd}
                  onClick={() => {
                    setOpenModal(true)
                    setFilId(null)
                  }}
                />
              </div>
              <div>
                <span className="SettingsUsers_Header_SubTitle">
                  Gerencie ou crie descontos de filiação
                </span>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => event.preventDefault()}>
                <input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={searchInput}
                  onChange={handleChangeFilter}
                  placeholder="Pesquisar"
                  maxLength="25"
                />
              </form>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>% (Máx.)</th>
                  <th>Tipo de Veículo</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filterResult.map((filiacao) => (
                  <tr>
                    <td>{filiacao.FIL_NOME.toUpperCase()}</td>
                    <td>{filiacao.FIL_PORCENTAGEM}%</td>
                    <td>{filiacao.FIL_TIPO_VEICULO}</td>
                    <td>
                      <div
                        className={`SettingsUsers__Status ${
                          filiacao.FIL_ATIVO
                            ? 'SettingsUsers__Status--active'
                            : 'SettingsUsers__Status--inactive'
                        }`}
                      >
                        {filiacao.FIL_ATIVO ? 'ATIVO' : 'ARQUIVADO'}
                      </div>
                    </td>
                    <td>
                      <ButtonDropDownMenu filiacao={filiacao} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    </FiliacaoContainer>
  )
}
