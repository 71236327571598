import { useState, useEffect } from 'react'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'
import CardSelectVistoria from '../../../../components/CardSelectVistoria/CardSelectVistoria'
import { VistoriasContainer } from './styles'
import { Alerts } from '../../../../utils/alert'

export function Vistorias({ setLoading }) {
  const [vistoriaSelecionada, setVistoriaSelecionada] = useState('')

  const endorCard = {
    icon: 'endor',
    title: 'ENDOR',
    content:
      'Selecione essa opção para realizar o processo de vistorias pelo aplicativo Endor Vistoria e Antifraude',
  }

  const driveCard = {
    icon: 'drive',
    title: 'GOOGLE DRIVE',
    content:
      'Selecione essa opção para realizar o processo de vistorias pela pasta do Google Drive da associação',
  }

  async function buscarVistorias() {
    try {
      const { type } = await parametrosRepository.listarVistorias()
      setVistoriaSelecionada(type.toLowerCase())

      Alerts.SUCCESS('Vistoria selecionada com sucesso!')

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(true)
    buscarVistorias()
  }, [])

  return (
    <VistoriasContainer>
      <section>
        <header>
          <div>
            <div className="SettingsUsers_Header_Title">
              <span className="SettingsUsers_Header_Title">Vistorias</span>
            </div>
            <div>
              <span className="SettingsUsers_Header_SubTitle">
                Selecione as vistorias
              </span>
            </div>
          </div>
        </header>
        <main>
          <div className="vistoria-options">
            <CardSelectVistoria
              cardContent={endorCard}
              vistoriaSelecionada={vistoriaSelecionada}
              setVistoriaSelecionada={setVistoriaSelecionada}
            />
          </div>
        </main>
      </section>
    </VistoriasContainer>
  )
}
