import { useEffect, useState } from 'react'
import ImgAdd from '../../../../Home/assets/icon-add.svg'
import menuAjustesTable from '../../../../Home/assets/menu-ajustes-table.svg'
import { CotacaoContainer } from './styles'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import editPencil from '../../../../Home/assets/icon-edit-pencil.svg'
import archiveIcon from '../../../../Home/assets/icon-arquivar.svg'
import { ModalCotacao } from './ModalCotacao/ModalCotacao'
import { ModalArchive } from './ModalArchive/ModalArchive'

export function Cotacao({ setLoading }) {
  const [filterResult, setFilterResult] = useState([])
  const [dataList, setDataList] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [changeModal, setChangeModal] = useState(false)
  const [cotId, setCotId] = useState(null)
  const [modalArchiveCotacao, setModalArchiveCotacao] = useState(false)
  const [cotacaoData, setCotacaoData] = useState([])

  useEffect(() => {
    setLoading(true)
    buscarTabelaCotaParticipacao()
    setChangeModal(false)
  }, [changeModal])

  async function buscarTabelaCotaParticipacao() {
    try {
      const { dados } =
        await parametrosRepository.listarTabelaCotaParticipacao()
      console.log('TABELA MENSALIDADE', dados)
      setDataList(dados)
      setFilterResult(dados)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = dataList.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })

    setFilterResult(filteredData)
  }

  useEffect(() => {
    buscarTabelaCotaParticipacao()
  }, [])

  function ButtonDropDownMenu({ cotacao }) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '5rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!cotacao.COT_ATIVO}
              onClick={() => {
                setCotId(cotacao.COT_ID)
                setOpenModal(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setCotacaoData(cotacao)
                setModalArchiveCotacao(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>{cotacao.COT_ATIVO ? 'Arquivar' : 'Desarquivar'}</span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <CotacaoContainer>
      <>
        {openModal && (
          <ModalCotacao
            openModal={openModal}
            setOpenModal={setOpenModal}
            cotacaoId={cotId}
            changeModal={setChangeModal}
            setLoading={setLoading}
          />
        )}

        {modalArchiveCotacao && (
          <ModalArchive
            openModal={modalArchiveCotacao}
            setOpenModal={setModalArchiveCotacao}
            changeModal={setChangeModal}
            cotacaoData={cotacaoData}
          />
        )}

        <section>
          <header>
            <div>
              <div className="SettingsUsers_Header_Title">
                <span className="SettingsUsers_Header_Title">
                  Cota de Participação
                </span>
                <img
                  src={ImgAdd}
                  onClick={() => {
                    setCotId(null)
                    setOpenModal(true)
                  }}
                />
              </div>
              <div>
                <span className="SettingsUsers_Header_SubTitle">
                  Gerencie ou crie a sua cota de participação
                </span>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => event.preventDefault()}>
                <input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={searchInput}
                  onChange={handleChangeFilter}
                  placeholder="Pesquisar"
                  maxLength="25"
                />
              </form>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>% (Min. / Máx)</th>
                  <th>Valor Mínimo</th>
                  <th>Tipo de Veículo</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filterResult.map((cotacao) => {
                  const valorMinimo = Number(
                    cotacao.COT_VALOR_MINIMO.replace('.', '').replace(',', '.'),
                  )

                  return (
                    <tr key={cotacao.COT_ID}>
                      <td>{cotacao.COT_NOME.toUpperCase()}</td>
                      <td>
                        {cotacao.COT_PORCENTAGEM_MINIMA}% /{' '}
                        {cotacao.COT_PORCENTAGEM_MAXIMA}%
                      </td>
                      <td>
                        {valorMinimo.toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td>{cotacao.COT_TIPO_VEICULO}</td>
                      <td>
                        <div
                          className={`SettingsUsers__Status ${
                            cotacao.COT_ATIVO
                              ? 'SettingsUsers__Status--active'
                              : 'SettingsUsers__Status--inactive'
                          }`}
                        >
                          {cotacao.COT_ATIVO ? 'ATIVO' : 'ARQUIVADO'}
                        </div>
                      </td>
                      <td>
                        <ButtonDropDownMenu cotacao={cotacao} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </main>
        </section>
      </>
    </CotacaoContainer>
  )
}
