import { useEffect, useState } from 'react'
import ImgAdd from '../../../Home/assets/icon-add.svg'
import menuAjustesTable from '../../../Home/assets/menu-ajustes-table.svg'
import menuAjustesTableFill from '../../../Home/assets/menu-ajustes-table-fill.svg'
import { AutomacoesContainer } from './styles'
import { oportunidadeRepository } from '../../../../api/repositories/oportunidadesRepository'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'
import { AutomacaoModal } from './AutomacaoModal/AutomacaoModal'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import editPencil from '../../../Home/assets/icon-edit-pencil.svg'
import archiveIcon from '../../../Home/assets/icon-arquivar.svg'
import { ModalArchive } from './ModalArchive/ModalArchive'

export function Automacoes({ setLoading }) {
  const VEN_ID = sessionStorage.getItem('VEN_ID')

  useEffect(() => {
    setLoading(true)
  }, [])

  const DEFAULT_AUTOMACAO = {
    AUT_ID: '',
    AUT_NAME: '',
    FUN_ID: '',
    AUT_TRIGGER: '',
    INFOS: [],
  }

  const [filterResult, setFilterResult] = useState([])
  const [automacoesList, setAutomacoesList] = useState([])
  const [funis, setFunis] = useState([])
  const [automacao, setAutomacao] = useState(DEFAULT_AUTOMACAO)
  const [searchInput, setSearchInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [archiveAutomacao, setArchiveAutomacao] = useState(false)
  const [autId, setAutId] = useState('')
  const [changeModal, setChangeModal] = useState(false)
  const [isArchived, setIsArchived] = useState(false)

  useEffect(() => {
    setLoading(true)
    listarFunisOportunidade()
    buscarAutomacao()
    setChangeModal(false)
  }, [changeModal])

  console.log(automacao)

  async function listarFunisOportunidade() {
    try {
      const data = await oportunidadeRepository.listarFunisOportunidade(VEN_ID)

      setFunis(
        data.funis.map((e) => {
          return {
            value: e.FUN_ID,
            label: e.FUN_NOME,
          }
        }),
      )
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function buscarAutomacao() {
    try {
      const data = await parametrosRepository.listarAutomacao()
      setAutomacoesList(data?.dados)
      setFilterResult(data?.dados)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = automacoesList.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })

    setFilterResult(filteredData)
  }

  function ButtonDropDownMenu({ automacao }) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '5rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!automacao.AUT_ACTIVE}
              onClick={() => {
                setAutomacao({
                  AUT_ID: automacao.AUT_ID,
                  AUT_NAME: automacao.AUT_NAME,
                  FUN_ID: automacao.FUN_ID,
                  AUT_TRIGGER: automacao.TRIGGERS[0].AUT_TRIGGER,
                  INFOS: automacao.TRIGGERS.map((el) => {
                    return {
                      AUT_TRIGGER: el.AUT_TRIGGER,
                      AUT_EVENT: el.AUT_EVENT,
                      AUT_FROM: el.AUT_FROM,
                      AUT_TO: el.AUT_TO,
                      AUT_ACTIONS: el.AUT_ACTIONS,
                    }
                  }),
                })
                setOpenModal(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setIsArchived(automacao.AUT_ACTIVE)
                setAutId(automacao.AUT_ID)
                setArchiveAutomacao(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>{automacao.AUT_ACTIVE ? 'Arquivar' : 'Desarquivar'}</span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <AutomacoesContainer>
      <>
        {openModal && (
          <AutomacaoModal
            setOpenModal={setOpenModal}
            funis={funis}
            automacao={automacao}
            setAutomacao={setAutomacao}
            changeModal={setChangeModal}
          />
        )}

        {archiveAutomacao && (
          <ModalArchive
            AUT_ID={autId}
            setAutId={setAutId}
            openModal={archiveAutomacao}
            setOpenModal={setArchiveAutomacao}
            changeModal={setChangeModal}
            isArchived={isArchived}
          />
        )}

        <section>
          <header>
            <div>
              <div className="SettingsUsers_Header_Title">
                <span className="SettingsUsers_Header_Title">Automações</span>
                <img src={ImgAdd} onClick={() => setOpenModal(true)} />
              </div>
              <div>
                <span className="SettingsUsers_Header_SubTitle">
                  Crie novas automações ou gerencia as existentes.
                </span>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => event.preventDefault()}>
                <input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={searchInput}
                  onChange={handleChangeFilter}
                  placeholder="Pesquisar"
                  maxLength="25"
                />
              </form>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Tipo</th>
                  <th>Funil</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filterResult.map((automacao) => (
                  <tr key={automacao.AUT_ID}>
                    <td>{automacao.AUT_NAME.toUpperCase()}</td>
                    <td>{automacao.TRIGGERS[0]?.AUT_TRIGGER}</td>
                    <td>{automacao.FUN_NOME}</td>
                    <td>
                      <div
                        className={`SettingsUsers__Status ${
                          automacao.AUT_ACTIVE
                            ? 'SettingsUsers__Status--active'
                            : 'SettingsUsers__Status--inactive'
                        }`}
                      >
                        {automacao.AUT_ACTIVE ? 'ATIVO' : 'ARQUIVADO'}
                      </div>
                    </td>
                    <td>
                      <ButtonDropDownMenu automacao={automacao} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    </AutomacoesContainer>
  )
}
