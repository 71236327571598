import styled from 'styled-components'
import lupaIcon from '../assets/icon-lupa.svg'
// import { Link } from "react-router-dom";

export const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  /* margin: 10px 20px 0px 20px; */
  margin-top: 10px;
  border-radius: 4px;
  /* border: 1px solid grey; */
  box-shadow: 1px 0px 4px #eee;
`

export const CardTableHeader = styled.div`
  background-color: white;
  margin: 0;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  padding: 15px;

  @media only screen and (max-width: 300px) {
    display: block;
  }
`

export const CardTableHeaderNoTitle = styled.div`
  background-color: white;
  margin: 0;
  border: 1px solid #ccc;
  display: flex;
  justify-content: end;
  -webkit-justify-content: flex-end;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  /* border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 0; */
  padding: 15px;

  @media only screen and (max-width: 575px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    -webkit-justify-content: flex-end;
  }

  .label {
    align-items: center;
    font-size: 13px;
    .icon {
      margin: 0 0.25rem 0 0.5rem;
    }
    strong {
      margin-right: 0.4rem;
    }
    .icon,
    strong {
      color: #534545;
    }
  }

  .select select {
    padding: 3px 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;

    :focus {
      outline: none;
    }
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: end;
  -webkit-justify-content: flex-end;
  padding: 0.25em;

  @media only screen and (max-width: 575px) {
    color: red;
    margin-bottom: 10px;
  }
`

export const TableSearchContainer = styled.div`
  display: flex;

  border-radius: 8px;
  line-height: 2rem;

  padding-left: 0.5rem;

  background: #f2f2f2;
`

export const TableSearch = styled.input`
  width: 100%;
  line-height: 2rem;
  background: #f2f2f2;

  border: none;
  border-radius: 8px;
  outline: none;

  text-indent: 0.5rem;
`

export const TableSearchButton = styled.button`
  padding: 0.25em 8px;
  border-radius: 0px 4px 4px 0px;
  background-color: #eee;
  border: 1px solid #ccc;
  text-align: center;
  color: #373737;
  /* :hover {
    background-color: #ddd;
  } */
`

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  position: sticky;

  thead {
    line-height: 2rem;
  }

  th {
    padding-left: 0.5rem;
    color: #838387;
    text-align: inherit;
    font-weight: normal;
    font-size: 0.9375rem;
    border-bottom: 0.2px solid #e2e2e2;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  tbody {
    border-collapse: separate;
    border-spacing: 0;

    tr {
      line-height: 3.1rem;
      font-size: 0.92rem;

      td:first-child {
        display: flex;
        align-items: center;
        padding: 0 4px;
        gap: 0.6rem;
      }

      td {
        color: #000;
        font-weight: 500;
        padding-left: 0;
      }
    }
  }

  //   /* overflow-x: scroll;
  //   width: auto; */
  //   /* margin: 5px; */
  //   /* border-radius: 0px 0px 4px 4px;
  //   background-color: #f0f0f0;
  //   margin: 0px;
  //   border-collapse: collapse;
  //   border-top: 0;
  //   border-right: 1px solid #ccc;
  //   border-left: 1px solid #ccc;
  //   border-bottom: 1px solid #ccc; */

  //   /* @media (max-width: 700px){
  //     width: 75%;
  //   } */

  //   @media only screen and (max-width: 950px) {
  //     display: block;
  //   }

  //   tr {
  //     border-bottom: 1px solid #ccc;

  //     @media only screen and (max-width: 622px) {
  //       margin-bottom: 15px;
  //     }
  //   }

  //   td {
  //     @media only screen and (max-width: 622px) {
  //       text-align: right;
  //       /* padding-left: 50%; */
  //       /* position: relative; */
  //     }
  //   }

  //   tr > th {
  //     padding: 10px 12px;
  //     text-align: left;
  //     border-bottom: 1px solid #ccc;
  //   }

  //   tr:nth-child(even) {
  //     background-color: white;
  //   }

  //   tr > td {
  //     padding: 8px 12px;
  //     text-align: left;
  //     /* border-bottom: 1px solid #ccc; */
  //     /* background-color: white; */
  //   }

  //   #opcoes_oportunidade {
  //     background-color: rgba(180, 180, 180, 0.1);
  //     /* border-bottom: 1px solid #ccc; */
  //     border-bottom: none;
  //     display: block;
  //     text-align: center;
  //     @media only screen and (max-width: 1004px) {
  //       display: block;
  //       text-align: center;
  //     }

  //     #btn-acoes {
  //       background-color: none;
  //       border: none;
  //       padding: 0;
  //       margin: 0 auto;
  //       color: #31303f;
  //       :hover {
  //         cursor: pointer;
  //         color: black;
  //       }
  //     }
  //   }

  //   thead {
  //     background-color: white;
  //     /* @media only screen and (max-width: 622px){
  //     display: none;
  //   } */
  //   }
`

export const TableButton = styled.button`
  /* display: flex; */
  padding: 0;
  /* margin: 0 2px;  */
  border: none;
  align-items: center;
  background-color: transparent;
  color: #31303f;
  :hover {
    cursor: pointer;
    color: black;

    .svgEndor {
      fill: #000;
      stroke: #000;
    }
  }

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;

    .svgEndor {
      fill: rgba(49, 48, 63, 0.5);
      stroke: rgba(49, 48, 63, 0.5);
    }
  }

  @media only screen and (max-width: 990px) {
    display: block;
    margin: 10px auto;
  }
`

export const TableALink = styled.a`
  /* display: flex; */
  /* padding: 0;
  margin: 0 2px; */
  border: none;
  align-items: center;
  background-color: transparent;
  color: #31303f;
  font-size: 14px;

  :hover {
    cursor: pointer;
    color: black;

    .svgEndor {
      fill: #000;
      stroke: #000;
    }
  }

  :disabled {
    color: rgba(49, 48, 63, 0.5);
    cursor: not-allowed;

    .svgEndor {
      fill: rgba(49, 48, 63, 0.5);
      stroke: rgba(49, 48, 63, 0.5);
    }
  }

  @media only screen and (max-width: 990px) {
    display: block;
    margin: 10px auto;
  }
`

export const ButtonInsideWrapper = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  align-items: center;
  gap: 0.9375rem;

  span {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 500;
  }
`

export const TableDeleteButton = styled.button`
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  align-items: center;
  background-color: transparent;
  color: #b21c1c;
  :hover {
    cursor: pointer;
    color: #57101c;
  }
  @media only screen and (max-width: 990px) {
    display: block;
    margin: 10px auto;
  }
`

export const Actions = styled.button`
  position: relative;
  color: #31303f;
  border: none;
  :hover {
    cursor: pointer;
    color: black;
  }
`

export const Options = styled.div`
  display: none;
  position: absolute;
  padding: 15px;
  border-radius: 4px;
  background-color: white;
  right: 5em;
  box-shadow: 0px 0px 3px #ccc;
  /* left: 100em; */
`
