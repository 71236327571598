import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from '../../../../../colors'

export const AddButton = styled(Link)`
  padding: 12px 15px 0px 15px;
  height: 30px;
  background-color: ${COLORS.primary};
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 110px;
  text-align: center;
`

export const Container = styled.div`
  padding: 48px 64px 0;
  display: flex;
  flex-direction: column;
  gap: 3.125rem;

  header {
    display: flex;
    justify-content: space-between;

    div > p {
      line-height: 2rem;
    }

    > div {
      width: 100%;
    }
  }

  .searchBoxTable {
    max-width: 21.4375rem;
  }
`

export const SwitchBotoes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  @media only screen and (max-width: 600px) {
    margin: 0 0 10px 0;
  }

  h2 {
    font-size: 2.5em;
  }

  .funis {
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    :focus {
      outline: none;
      border: 1px solid lightblue;
    }
  }

  button {
    border: none;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.4rem;
    height: 1.3rem;
    background-color: #fff;
    color: ${COLORS.labelDark};
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    :hover {
      background-color: ${COLORS.lightGray};
    }
  }

  .active {
    background-color: #1768e4;
    color: white;

    :hover {
      background-color: #1e3f8d;
    }
  }

  .addOportunity {
    cursor: pointer;
  }

  .circle {
    width: 31px;
    height: 32px;
    border-radius: 50%;
    background-color: #1768e4;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      width: 50%; /* A imagem ocupa toda a largura da div */
      height: 50%; /* A imagem ocupa toda a altura da div */
      object-fit: cover; /* Garante que a imagem seja cortada para se ajustar ao círculo */
      border-radius: 50%;
    }
  }

  .dropdownToggle_OptionsMenu {
    border: none;
    background: none;
    width: 31px;
    height: 31px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`

export const FunisBotoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    border-radius: 8px;
    background-color: #fff;
    width: fit-content;

    @media only screen and (max-width: 600px) {
      margin: 0 0 10px 0;
    }

    span {
      background-color: #fff;
      padding: 6px;
      box-shadow: 0 2px 10px #d9d9d980;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 500;

      :hover {
        cursor: pointer;
      }
    }

    .btn_filter {
      padding: 0;
      color: ${COLORS.primary};
      background-color: transparent;
      border: 0;
      :hover {
        color: ${COLORS.primaryHover};
        background-color: transparent;
      }
    }
  }
`
