import { Fragment, useContext, useState } from 'react'
import Header from '../../components/Header/Header'
import { Tab } from '@headlessui/react'
import {
  SettingsContainer,
  SettingsContainerAside,
  SettingsSubMenu,
} from './styledParametrosContainer'
import { ConfigSistema } from './Ajustes/ConfigSistema/ConfigSistema'
import { Usuarios } from './Ajustes/Usuarios/Usuarios'
import { Tarefas } from './Ajustes/Tarefas/Tarefas'
import { OrigemLead } from './Ajustes/OrigemLead/OrigemLead'
import { Modelos } from './Ajustes/Modelos/Modelos'
import { Automacoes } from './Ajustes/Automacoes/Automacoes'
import { FunilVendas } from './Ajustes/FunilVendas/FunilVendas'
import ImgUsrFill from '../Home/assets/icon-usr-fill.svg'
import ImgUsr from '../Home/assets/icon-usr.svg'
import {
  RiBook2Fill,
  RiBook2Line,
  RiDropboxFill,
  RiDropboxLine,
  RiSurveyFill,
  RiSurveyLine,
  RiTableFill,
  RiTableLine,
} from 'react-icons/ri'
import { FaBookmark, FaRegBookmark } from 'react-icons/fa'
import { TiThList, TiThListOutline } from 'react-icons/ti'
import { PiGear, PiGearFill } from 'react-icons/pi'
import { HiOutlineTemplate, HiTemplate } from 'react-icons/hi'
import { TabelaDesconto } from './Ajustes/TabelaDesconto/TabelaDesconto'
import { Filiacao } from './Ajustes/TabelaDesconto/Filiacao/Filiacao'
import { Mensalidade } from './Ajustes/TabelaDesconto/Mensalidade/Mensalidade'
import { Cotacao } from './Ajustes/TabelaDesconto/Cotacao/Cotacao'
import Loading from '../Loading/Loading'
import { Vistorias } from './Ajustes/Vistorias/Vistorias'
import { AuthContext } from '../../context/auth'
import { useNavigate } from 'react-router-dom'

export function ParametrosContainer() {
  const { PER_ID } = useContext(AuthContext)

  console.log(PER_ID)

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  return (
    <>
      {loading && <Loading />}
      <Header />
      {PER_ID ? (
        <Tab.Group vertical>
          <SettingsContainer>
            <SettingsContainerAside>
              <h1>Ajustes</h1>
              <SettingsSubMenu>
                <span>Configurações</span>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            {selected ? (
                              <PiGearFill size={19} />
                            ) : (
                              <PiGear size={19} />
                            )}
                            Sistema
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            {selected ? <RiSurveyFill /> : <RiSurveyLine />}
                            Vistorias
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <span style={{ marginTop: '1rem' }}>Parametros</span>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            <img
                              src={selected ? ImgUsrFill : ImgUsr}
                              style={{ width: '24px', height: '14px' }}
                            />
                            Usuários
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            {selected ? (
                              <RiBook2Fill size={18} />
                            ) : (
                              <RiBook2Line size={18} />
                            )}
                            Tarefas
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            {selected ? (
                              <FaBookmark size={16} />
                            ) : (
                              <FaRegBookmark size={16} />
                            )}
                            Origem
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <span style={{ marginTop: '1rem' }}>Tabelas de Descontos</span>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <div>
                            <button className={selected ? 'isActive' : ''}>
                              {selected ? (
                                <TiThList size={18} />
                              ) : (
                                <TiThListOutline size={18} />
                              )}
                              Filiação
                            </button>
                          </div>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <div>
                            <button className={selected ? 'isActive' : ''}>
                              {selected ? (
                                <TiThList size={18} />
                              ) : (
                                <TiThListOutline size={18} />
                              )}
                              Mensalidade
                            </button>
                          </div>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <div>
                            <button className={selected ? 'isActive' : ''}>
                              {selected ? (
                                <TiThList size={18} />
                              ) : (
                                <TiThListOutline size={18} />
                              )}
                              Cota de Participação
                            </button>
                          </div>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <span style={{ marginTop: '1rem' }}>Processos</span>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            {selected ? <HiTemplate /> : <HiOutlineTemplate />}
                            Modelos
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            {selected ? <RiDropboxFill /> : <RiDropboxLine />}
                            Automações
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
                <nav>
                  <ul>
                    <li>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button className={selected ? 'isActive' : ''}>
                            {selected ? (
                              <RiTableFill size={18} />
                            ) : (
                              <RiTableLine size={18} />
                            )}
                            Funis
                          </button>
                        )}
                      </Tab>
                    </li>
                  </ul>
                </nav>
              </SettingsSubMenu>
            </SettingsContainerAside>

            <section>
              <Tab.Panels>
                {/* Configurações */}
                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <ConfigSistema setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Vistorias setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                {/* Parametros */}
                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Usuarios setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Tarefas setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <OrigemLead setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                {/* Tabelas de Descontos */}
                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Filiacao setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Mensalidade setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Cotacao setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                {/* Processos */}
                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Modelos setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <Automacoes setLoading={setLoading} /> : null
                  }
                </Tab.Panel>

                <Tab.Panel as={Fragment}>
                  {({ selected }) =>
                    selected ? <FunilVendas setLoading={setLoading} /> : null
                  }
                </Tab.Panel>
              </Tab.Panels>
            </section>
          </SettingsContainer>
        </Tab.Group>
      ) : (
        navigate('/home/dashboard')
      )}
    </>
  )
}
