import {
  BackgroundModal,
  CloseButton,
  Container,
  ContentModal,
  FormCol,
  FormField,
  FormRow,
  FormStep,
  Header,
  Label,
  ListboxWrapper,
  Title,
  ErrorInput,
} from './styles'
import ImgClose from '../../../../../Home/assets/icon-close.svg'
import { useForm } from 'react-hook-form'
import { Listbox } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { parametrosRepository } from '../../../../../../api/repositories/parametrosRepository'
import { Checkbox } from '@mui/material'
import ImgIconUser from '../../../../../Home/assets/icon-usr-register-modal.svg'
import { veiculoRepository } from '../../../../../../api/repositories/veiculosRepository'
import { NumericFormat } from 'react-number-format'
import { Alerts } from '../../../../../../utils/alert'

const DEFAULT_VALUES = {
  descricaoCotacao: '',
  porcentagemMaxima: 0,
  porcentagemMinima: 0,
  valorMinimo: 0,
  vendedores: [],
  veiId: {},
}

export function ModalCotacao({
  openModal,
  setOpenModal,
  cotacaoId,
  changeModal,
  setLoading,
}) {
  const [vendedores, setVendedores] = useState([])
  const [selectedVendedores, setSelectedVendedores] = useState([])
  const [query, setQuery] = useState('')
  const [cotacaoDados, setCotacaoDados] = useState(DEFAULT_VALUES)
  const [tiposVeiculo, setTiposVeiculo] = useState([])
  const [selectedVeiculo, setSelectedVeiculo] = useState('')

  const { register, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: DEFAULT_VALUES,
  })

  const porcentagemMinima = watch('porcentagemMaxima')
  const porcentagemMaxima = watch('porcentagemMinima')

  const isValidPorcentagem =
    Number(porcentagemMinima) < Number(porcentagemMaxima)

  useEffect(() => {
    setLoading(true)
    if (cotacaoId) {
      buscarTabelaFiliacao()
    }
    buscarVendedores()
    buscarTiposVeiculos()
  }, [cotacaoId])

  useEffect(() => {
    if (cotacaoId && cotacaoDados) {
      reset(cotacaoDados)

      setSelectedVendedores(cotacaoDados.vendedores)
      setSelectedVeiculo(cotacaoDados.veiId)
      setValue('porcentagemMaxima', cotacaoDados.porcentagemMaxima)
      setValue('porcentagemMinima', cotacaoDados.porcentagemMinima)
    }
  }, [cotacaoDados, vendedores, reset, setValue])

  async function onSubmit(dataSubmitting) {
    const COT_NOME = dataSubmitting.descricaoCotacao
    const COT_PORCENTAGEM_MAXIMA = Number(dataSubmitting.porcentagemMaxima)
    const COT_PORCENTAGEM_MINIMA = Number(dataSubmitting.porcentagemMinima)
    const COT_VALOR_MINIMO = cotacaoDados.valorMinimo
    const COT_VEN_IDS = selectedVendedores
    const COT_TIPO_VEICULO = dataSubmitting.veiId
    const COT_ID = cotacaoId

    if (cotacaoId) {
      const editCotacaoResponse = {
        COT_ID,
        COT_NOME,
        COT_PORCENTAGEM_MAXIMA,
        COT_PORCENTAGEM_MINIMA,
        COT_VALOR_MINIMO,
        COT_VEN_IDS,
        COT_TIPO_VEICULO,
      }

      await editarFiliacao(editCotacaoResponse)
    } else {
      const criarCotacaoResponse = {
        COT_NOME,
        COT_PORCENTAGEM_MAXIMA,
        COT_PORCENTAGEM_MINIMA,
        COT_VALOR_MINIMO,
        COT_VEN_IDS,
        COT_TIPO_VEICULO,
      }

      await criarCotacao(criarCotacaoResponse)
    }

    changeModal(true)
  }

  async function buscarVendedores() {
    try {
      const vendedores = await parametrosRepository.buscarVendedores()
      setVendedores(vendedores)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  async function criarCotacao(cotacaoDados) {
    try {
      const data =
        await parametrosRepository.criarTabelaCotaParticipacao(cotacaoDados)

      if (data.error) {
        Alerts.ERROR(data.message || 'Erro inesperado ao cadastrar cotação!')
        return
      }

      setOpenModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function editarFiliacao(cotacaoDados) {
    try {
      const data =
        await parametrosRepository.editarTabelaCotaParticipacao(cotacaoDados)

      if (data.error) {
        Alerts.ERROR(data.message || 'Erro inesperado ao editar cotação!')
        return
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function buscarTabelaFiliacao() {
    try {
      const data =
        await parametrosRepository.buscarTabelaCotaParticipacao(cotacaoId)
      setCotacaoDados({
        descricaoCotacao: data?.dados[0].COT_NOME,
        porcentagemMaxima: Number(data?.dados[0].COT_PORCENTAGEM_MAXIMA),
        porcentagemMinima: Number(data?.dados[0].COT_PORCENTAGEM_MINIMA),
        valorMinimo: `R$${data?.dados[0].COT_VALOR_MINIMO}`,
        vendedores: data?.dados[0].COT_VEN_IDS,
        veiId: data?.dados[0].COT_TIPO_VEICULO,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function buscarTiposVeiculos() {
    try {
      const { tipos } = await veiculoRepository.buscarTiposVeiculos()
      setTiposVeiculo(tipos)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  function handleSelectVendedor(vendedor) {
    setSelectedVendedores(vendedor)
    setValue('vendedorId', vendedor)
  }

  function handleSelectVeiculo(veiculo) {
    setSelectedVeiculo(veiculo)
    setValue('veiId', veiculo)
  }

  const filtrarVendedores =
    query === ''
      ? vendedores
      : vendedores.filter((vendedor) => {
          return vendedor.label.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <CloseButton onClick={() => setOpenModal(false)}>
                <img src={ImgClose} alt="Fechar modal" />
              </CloseButton>

              <div>
                <Title>
                  {cotacaoId
                    ? 'EDITAR COTA PARTICIPAÇÃO'
                    : 'NOVA COTA PARTICIPAÇÃO'}
                </Title>
              </div>
            </Header>
            <main>
              <FormStep>
                <FormRow>
                  <FormField style={{ width: '100%' }}>
                    <Label>Descrição</Label>
                    <input
                      type="text"
                      {...register('descricaoCotacao')}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                    />
                  </FormField>
                </FormRow>
                <FormCol>
                  <FormField>
                    <Label>Vendedor</Label>
                    <ListboxWrapper>
                      <Listbox
                        value={selectedVendedores}
                        onChange={handleSelectVendedor}
                        multiple
                      >
                        <>
                          <Listbox.Button className="ListboxButton">
                            {selectedVendedores.length === 0
                              ? 'Selecione os Vendedores'
                              : selectedVendedores
                                  .map((vendedor) => vendedor.label)
                                  .join(', ')}
                          </Listbox.Button>

                          {
                            <Listbox.Options className="combobox-options">
                              <div className="combobox-search">
                                <input
                                  type="text"
                                  placeholder="Pesquisar..."
                                  value={query}
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                />
                              </div>

                              <div
                                className="combobox-option"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                onClick={() => {
                                  selectedVendedores.length ===
                                  vendedores.length
                                    ? setSelectedVendedores([])
                                    : setSelectedVendedores(vendedores)
                                }}
                              >
                                <Checkbox
                                  checked={
                                    selectedVendedores.length ===
                                    vendedores.length
                                  }
                                  onClick={(event) => {
                                    event.target.checked
                                      ? setSelectedVendedores(vendedores)
                                      : setSelectedVendedores([])
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: '12px',
                                  }}
                                >
                                  Selecionar todos
                                </span>
                              </div>
                              {filtrarVendedores.map((vendedor) => (
                                <Listbox.Option
                                  value={vendedor}
                                  className="combobox-option"
                                >
                                  {({ selected }) => (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Checkbox checked={selected} />
                                      <span style={{ fontSize: '12px' }}>
                                        {vendedor.label}
                                      </span>
                                    </div>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          }
                        </>
                      </Listbox>
                    </ListboxWrapper>
                  </FormField>
                  <FormField>
                    <Label>Tipo de Véiculo</Label>
                    <input
                      type="hidden"
                      {...register('veiId')}
                      value={selectedVeiculo}
                    />
                    <ListboxWrapper>
                      <Listbox
                        value={selectedVeiculo}
                        onChange={handleSelectVeiculo}
                      >
                        <Listbox.Button className="ListboxButton">
                          {selectedVeiculo === ''
                            ? 'Selecione...'
                            : selectedVeiculo.label}
                        </Listbox.Button>

                        <Listbox.Options className="ListboxOptions">
                          {tiposVeiculo.map((veiculo) => (
                            <Listbox.Option
                              value={veiculo}
                              key={veiculo.value}
                              className="ListboxOption"
                            >
                              {veiculo.label}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </ListboxWrapper>
                  </FormField>
                </FormCol>

                <FormCol>
                  <FormField>
                    <Label>Porcentagem Máxima</Label>
                    <input type="number" {...register('porcentagemMaxima')} />
                  </FormField>
                  <FormField>
                    <Label>Porcentagem Mínima</Label>
                    <input type="number" {...register('porcentagemMinima')} />
                    {isValidPorcentagem ? (
                      <ErrorInput>
                        A porcetangem mínima é maior que máxima
                      </ErrorInput>
                    ) : null}
                  </FormField>
                  <FormField>
                    <Label>Valor Mínimo</Label>
                    <NumericFormat
                      prefix="R$"
                      thousandSeparator="."
                      decimalSeparator=","
                      allowLeadingZeros={false}
                      allowNegative={false}
                      decimalScale={2}
                      value={cotacaoDados.valorMinimo}
                      onChange={(e) => {
                        setCotacaoDados((prev) => ({
                          ...prev,
                          valorMinimo: e.target.value,
                        }))
                      }}
                    />
                  </FormField>
                </FormCol>
              </FormStep>
              <div className="save_button">
                <button type="submit" disabled={isValidPorcentagem}>
                  Salvar
                </button>
              </div>
            </main>
          </form>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
