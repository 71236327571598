import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../../../context/auth'
import {
  Container,
  Carousel,
  CardContainer,
  Card,
  Titles,
  Numero,
  SepLine,
  // BlocoEstatisticas,
  // BlocoEstatisticas2,
  // EstatiticasContainer,
  // EstatiticasContainer2,
  // DognutChart,
  // TaxasCard,
  // LineCard,
  // GraphCard,
} from './styledInicio'
import Loading from '../../../Loading/Loading'
import AlterarSenha from './AlterarSenha/AlterarSenha'
import ModalCliente from '../Oportunidades/Modal/ModalCliente'
import { FaPlus } from 'react-icons/fa'

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
//   PointElement,
//   LineController,
//   LineElement,
// } from "chart.js";
// import { Doughnut, Bar, Line } from "react-chartjs-2";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { SelectFiltroOpo } from '../Relatorios/styledRelatorios'
import './style/style.css'
import { filtroRepository } from '../../../../api/repositories/filtroRepository'
import { oportunidadeRepository } from '../../../../api/repositories/oportunidadesRepository'

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
//   PointElement,
//   Title,
//   LineController,
//   LineElement,
//   ChartDataLabels
// );

function Inicio() {
  const {
    PER_ID,
    FUN_ID,
    setFUN_ID,
    USR_NOME,
    versaoSistema,
    alterarSenhaLogin,
    setAlterarSenhaLogin,
    senhaAlterar,
  } = useContext(AuthContext)
  const [modal, setModal] = useState(false)

  const [VEN_ID, setVEN_ID] = useState('')
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [closeModal, setCloseModal] = useState(false)

  const [dados, setDados] = useState({})
  const [loading, setLoading] = useState(true)
  const [vendedores, setVendedores] = useState([])
  const [FILHOS, setFILHOS] = useState(false)

  const [dadosOpo, setDadosOpo] = useState({})

  const [taxaConversao, setTaxaConversao] = useState({})
  const [taxaCrescimento, setTaxaCrescimento] = useState({})
  const [taxaFollowUp, setTaxaFollowUp] = useState({})
  const [mediaTempoConversao, setMediaTempoConversao] = useState({})
  const [mediaTempoResposta, setMediaTempoResposta] = useState({})
  const [receitaPorVendedor, setReceitaPorVendedor] = useState({})
  const [lifeTimeValue, setLifeTimeValue] = useState({})
  const [ticketMedio, setTicketMedio] = useState({})
  const [indiceRetencao, setIndiceRetencao] = useState({})
  const [MRR, setMRR] = useState({})
  const [CAC, setCAC] = useState({})
  const [ROI, setROI] = useState({})

  const [graficosPermitidos, setGraficosPermitidos] = useState([])
  const alterarSenha = sessionStorage.getItem('ALTERAR_SENHA')
  const funilAtual = sessionStorage.getItem('FUN_ID')

  useEffect(() => {
    if (PER_ID == 0) {
      setVEN_ID(sessionStorage.getItem('VEN_ID'))
    }
  }, [PER_ID])

  useEffect(() => {
    if (alterarSenha === 'true') {
      setModal(true)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if ((PER_ID == 0 && VEN_ID != '') || PER_ID == 1) {
        try {
          const data =
            await oportunidadeRepository.listarDashboardOportunidades(
              PER_ID,
              VEN_ID,
              FILHOS,
            )
          // console.log('DASHBOARD OPORTUNIDADES:', data)
          setDados(data)
          setLoading(false)
        } catch (error) {
          console.error('Erro no componente:', error)
          alert('Algo deu errado')
          setLoading(false)
        }

        // await axios
        //   .get(
        //     `https://kaminocrm.com.br:7778/oportunidades/dashboard-oportunidades?PER_ID=${PER_ID}&VEN_ID=${VEN_ID}&FILHOS=${FILHOS}`,
        //     {
        //       headers: {
        //         Authorization: `Bearer ${JWT_TOKEN}`,
        //         user_login: LOGIN,
        //         ass_cod: ASS_COD,
        //       },
        //     },
        //   )
        //   .then((res) => {
        //     setDados(res.data)

        //     // setDadosOpo(res.data.taxasTotalOportunidade);

        //     // setTaxaConversao(res.data.taxaDeConversao);
        //     // setTaxaCrescimento(res.data.taxasDeCrescimento);
        //     // setTaxaFollowUp(res.data.taxaFollowUp);
        //     // setMediaTempoConversao(res.data.mediaTempoConversao);
        //     // setMediaTempoResposta(res.data.mediaTempoResposta);

        //     // setReceitaPorVendedor(res.data.receitaPorVendedor);

        //     // setLifeTimeValue(res.data.lifeTimeValue);
        //     // setTicketMedio(res.data.ticketMedio);

        //     // setIndiceRetencao(res.data.indiceRetencao);

        //     // setMRR(res.data.MRR);
        //     // setCAC(res.data.CAC);
        //     // setROI(res.data.ROI);

        //     setLoading(false)
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //     setLoading(false)
        //     alert('Algo deu errado')
        //   })

        try {
          const data = await filtroRepository.buscarFiltro(
            JWT_TOKEN,
            LOGIN,
            ASS_COD,
          )
          // console.log('OBTENDO FILTRO', data.dadosVendedor)
          setVendedores(data.dadosVendedor)
        } catch (error) {
          console.log(error)
        }

        /*  
        axios
          .get(`https://kaminocrm.com.br:7778/filtro/buscarFitlros`, {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          })
          .then((res) => {
            setVendedores(res.data.dadosVendedor)
          })
          .catch((err) => console.log(err))
        */
      }
      // axios
      //   .post(
      //     "https://kaminocrm.com.br:7778/parametros/buscarGraficosPorPerfilDeAcesso",
      //     { PER_ID: PER_ID.toString() },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${JWT_TOKEN}`,
      //         user_login: LOGIN,
      //         ass_cod: ASS_COD,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     setGraficosPermitidos(res.data.graficosNomes);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      const listarFunisOportunidade = async () => {
        try {
          const data =
            await oportunidadeRepository.listarFunisOportunidade(VEN_ID)
          // console.log('DADOS DOS FUNIS OPORTUNIDADE', data)
          console.log(funilAtual)
          if (!funilAtual) {
            setFUN_ID(data.funis[0].FUN_ID)
            sessionStorage.setItem('FUN_ID', FUN_ID)
          }
        } catch (error) {
          console.log(error)
        }
      }

      listarFunisOportunidade()

      /*
      axios
        .get(
          `https://kaminocrm.com.br:7778/oportunidades/listarFunisOportunidade?VEN_ID=${VEN_ID}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          },
        )
        .then((res) => {
          // console.log('funil', res)
          console.log(funilAtual)
          if (!funilAtual) {
            setFUN_ID(res.data.funis[0].FUN_ID)
            sessionStorage.setItem('FUN_ID', FUN_ID)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      */
    })()
  }, [, PER_ID, VEN_ID, FILHOS])

  useEffect(() => {
    sessionStorage.setItem('FUN_ID', FUN_ID)
  }, [FUN_ID])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {modal && <AlterarSenha />}

          <Container
            style={{
              backgroundImage:
                'linear-gradient(white, #f8f8f8, #f3f3f3, #f8f8f8, white)',
            }}
          >
            {closeModal && <ModalCliente closeModal={setCloseModal} />}

            <div
              style={{
                margin: '0.5rem 0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h2
                style={{
                  fontSize: '2.5rem',
                  margin: '.7rem 0',
                }}
              >
                Início
              </h2>

              {PER_ID == 0 ? (
                <button
                  to="cadastro-cliente"
                  className="btnmob"
                  onClick={() => {
                    setCloseModal(true)
                  }}
                  style={{ padding: '7px 10px' }}
                >
                  <FaPlus size={11} /> Nova Oportunidade
                </button>
              ) : (
                ''
              )}
            </div>

            <div
              style={{
                display: `${PER_ID == 1 ? 'flex' : 'none'}`,
                justifyContent: 'center',
                alignItems: 'start',
                flexDirection: 'column',
              }}
            >
              <p style={{ margin: '0', color: '#534545' }}>
                <strong>Filtragem das oportunidades </strong>
              </p>
              <SelectFiltroOpo
                name=""
                id=""
                style={{ margin: '10px 0' }}
                onChange={(e) => setVEN_ID(e.target.value)}
              >
                <option value="null">Ver todas as oportunidades...</option>
                {vendedores.map((ven) => {
                  return (
                    <option key={ven.value} value={ven.value}>
                      {ven.label}
                    </option>
                  )
                })}
              </SelectFiltroOpo>
            </div>

            <div>
              <input
                type="checkbox"
                value={FILHOS}
                onChange={() => {
                  setFILHOS(!FILHOS)
                }}
                checked={FILHOS ? true : false}
              />
              <label htmlFor="">Mostrar filhos</label>
            </div>

            <h3>Oportunidades</h3>
            <Carousel>
              <CardContainer>
                <Card>
                  <Titles>
                    <span className="title">CONVERTIDAS</span>
                    <SepLine
                      style={{
                        backgroundImage:
                          'linear-gradient(to right, #00B065, white)',
                      }}
                    />
                  </Titles>

                  <Numero>{dados.totalDeOportunidadesConvertida}</Numero>
                </Card>

                <Card>
                  <Titles>
                    <span className="title">PENDENTES</span>
                    <SepLine
                      style={{
                        backgroundImage:
                          'linear-gradient(to right, #459dd1, white)',
                      }}
                    />
                  </Titles>
                  <Numero>{dados.totalDeOportunidadesPendentes}</Numero>
                </Card>

                <Card>
                  <Titles>
                    <span className="title">PERDIDAS</span>
                    <SepLine
                      style={{
                        backgroundImage:
                          'linear-gradient(to right, #dc1c4c, white)',
                      }}
                    />
                  </Titles>
                  <Numero>{dados.totalDeOportunidadesPerdidas}</Numero>
                </Card>

                {/* <Card>
                  <Titles>
                    <span className="title">EM VISTORIA</span>
                    <SepLine
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #572A8A, white)",
                      }}
                    ></SepLine>
                  </Titles>
                  <Numero>{dados.totalDeOportunidadesEndor}</Numero>
                </Card> */}

                <Card>
                  <Titles>
                    <span className="title">TOTAIS</span>
                    <SepLine
                      style={{
                        backgroundImage:
                          'linear-gradient(to right, #636363, white)',
                      }}
                    />
                  </Titles>
                  <Numero>{dados.totalDeOportunidades}</Numero>
                </Card>
              </CardContainer>
            </Carousel>

            {/* <div style={{ display: "block" }}>
              <h3>Dados e gráficos</h3>
              <BlocoEstatisticas>
                <EstatiticasContainer>
                  {graficosPermitidos.includes("TOTAL DE OPORTUNIDADES") ? (
                    <DognutChart>
                      <h3>TOTAL DE OPORTUNIDADES %</h3>
                      <SepLine></SepLine>
                      <Doughnut
                        options={{
                          responsive: true,
                          plugins: {
                            plugins: [ChartDataLabels],
                            datalabels: {
                              backgroundColor: function (context) {
                                return context.dataset.backgroundColor;
                              },
                              borderColor: "white",
                              borderRadius: 25,
                              borderWidth: 0,
                              color: "white",
                              display: function (context) {
                                var dataset = context.dataset;
                                var count = dataset.data.length;
                                var value = dataset.data[context.dataIndex];
                                return value > count * 1.5;
                              },
                              font: {
                                weight: "bold",
                              },
                              padding: 6,
                              formatter: function (value, context) {
                                return Math.round(value) + "%";
                              },
                            },
                            legend: {
                              position: "bottom",
                              align: "center",
                            },
                            title: {
                              display: false,
                            },
                            subtitle: {
                              display: false,
                            },
                          },
                        }}
                        data={{
                          labels: [
                            graficosPermitidos.includes(
                              "TOTAL DE OPORTUNIDADES"
                            )
                              ? dadosOpo[0].nome
                              : "",
                            graficosPermitidos.includes(
                              "TOTAL DE OPORTUNIDADES"
                            )
                              ? dadosOpo[1].nome
                              : "",
                            graficosPermitidos.includes(
                              "TOTAL DE OPORTUNIDADES"
                            )
                              ? dadosOpo[2].nome
                              : "",
                            graficosPermitidos.includes(
                              "TOTAL DE OPORTUNIDADES"
                            )
                              ? dadosOpo[3].nome
                              : "",
                          ],

                          datasets: [
                            {
                              label: "Total de Oportunidades",
                              data: [
                                graficosPermitidos.includes(
                                  "TOTAL DE OPORTUNIDADES"
                                )
                                  ? dadosOpo[0].valor
                                  : "",
                                graficosPermitidos.includes(
                                  "TOTAL DE OPORTUNIDADES"
                                )
                                  ? dadosOpo[1].valor
                                  : "",
                                graficosPermitidos.includes(
                                  "TOTAL DE OPORTUNIDADES"
                                )
                                  ? dadosOpo[2].valor
                                  : "",
                                graficosPermitidos.includes(
                                  "TOTAL DE OPORTUNIDADES"
                                )
                                  ? dadosOpo[3].valor
                                  : "",
                              ],

                              backgroundColor: [
                                "#68998a",
                                "#459dd1",
                                "#776b9c",
                                "#b04549",
                              ],
                              borderColor: [
                                "#68998a",
                                "#459dd1",
                                "#776b9c",
                                "#b04549",
                              ],
                              hoverOffset: 0,
                              datalabels: {
                                align: "start",
                                anchor: "end",
                              },
                            },
                          ],
                        }}
                        width={1000}
                        heigth={800}
                      />
                    </DognutChart>
                  ) : (
                    ""
                  )}

                  {graficosPermitidos.includes("TAXAS") ||
                  graficosPermitidos.includes("LTV") ? (
                    <TaxasCard>
                      {graficosPermitidos.includes("TAXAS") ? (
                        <div>
                          <h3>TAXAS</h3>
                          <SepLine></SepLine>
                          <p>
                            <strong>FOLLOW UP:</strong>{" "}
                            {graficosPermitidos.includes("TAXAS")
                              ? taxaFollowUp
                              : ""}
                          </p>
                          <p>
                            <strong>TEMPO DE CONVERSÃO:</strong>{" "}
                            {graficosPermitidos.includes("TAXAS")
                              ? mediaTempoConversao
                              : ""}
                          </p>
                          <p>
                            <strong>TEMPO DE RESPOSTA:</strong>{" "}
                            {graficosPermitidos.includes("TAXAS")
                              ? mediaTempoResposta
                              : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {graficosPermitidos.includes("LTV") ? (
                        <div>
                          <h3>LTV</h3>
                          <SepLine></SepLine>
                          <p>
                            <strong>CARRO:</strong>{" "}
                            {graficosPermitidos.includes("LTV")
                              ? lifeTimeValue.Carro
                              : ""}
                          </p>
                          <p>
                            <strong>MOTO:</strong>{" "}
                            {graficosPermitidos.includes("LTV")
                              ? lifeTimeValue.Moto
                              : ""}
                          </p>
                          <p>
                            <strong>CAMINHÃO:</strong>{" "}
                            {graficosPermitidos.includes("LTV")
                              ? lifeTimeValue.Caminhão
                              : ""}
                          </p>
                          <p>
                            <strong>GERAL:</strong>{" "}
                            {graficosPermitidos.includes("LTV")
                              ? lifeTimeValue.Geral
                              : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </TaxasCard>
                  ) : (
                    ""
                  )}
                </EstatiticasContainer>
                <EstatiticasContainer>
                  {graficosPermitidos.includes("MRR CAC ROI") ? (
                    <>
                      <LineCard className="mini_card">
                        <h3>MRR</h3>
                        <SepLine></SepLine>
                        <span>{MRR}</span>
                      </LineCard>
                      <LineCard className="mini_card">
                        <h3>CAC</h3>
                        <SepLine></SepLine>
                        <span>{CAC}</span>
                      </LineCard>
                      <LineCard>
                        <h3>ROI</h3>
                        <SepLine></SepLine>
                        <span>{ROI}</span>
                      </LineCard>
                    </>
                  ) : (
                    ""
                  )}
                </EstatiticasContainer>
                <EstatiticasContainer2>
                  {graficosPermitidos.includes(
                    "TAXAS DE CONVERSÃO E RESPOSTA "
                  ) ? (
                    <LineCard>
                      <h3>TAXAS DE CONVERSÃO E RESPOSTA</h3>
                      <SepLine></SepLine>
                      <div className="chart">
                        <Line
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              datalabels: false,
                              legend: {
                                position: "bottom",
                                align: "center",
                              },
                            },
                          }}
                          data={{
                            labels: [
                              "Jan",
                              "Fev",
                              "Mar",
                              "Abr",
                              "Mai",
                              "Jun",
                              "Jul",
                              "Ago",
                              "Set",
                              "Out",
                              "Nov",
                              "Dez",
                            ],

                            datasets: [
                              {
                                type: "line",
                                label: graficosPermitidos.includes(
                                  "TAXAS DE CONVERSÃO E RESPOSTA "
                                )
                                  ? taxaCrescimento[0].nome
                                  : "",
                                fill: false,
                                data: graficosPermitidos.includes(
                                  "TAXAS DE CONVERSÃO E RESPOSTA "
                                )
                                  ? taxaCrescimento[0].valor
                                  : "",
                                borderColor: ["#459dd1"],
                              },
                              {
                                type: "line",
                                label: graficosPermitidos.includes(
                                  "TAXAS DE CONVERSÃO E RESPOSTA "
                                )
                                  ? taxaCrescimento[1].nome
                                  : "",
                                fill: false,
                                data: graficosPermitidos.includes(
                                  "TAXAS DE CONVERSÃO E RESPOSTA "
                                )
                                  ? taxaCrescimento[1].valor
                                  : "",
                                borderColor: ["#68998a"],
                              },
                            ],
                          }}
                          width={800}
                          heigth={800}
                        />
                      </div>
                    </LineCard>
                  ) : (
                    ""
                  )}
                </EstatiticasContainer2>
              </BlocoEstatisticas>

              <BlocoEstatisticas2>
                <EstatiticasContainer>
                  {graficosPermitidos.includes("TICKET MEDIO") ? (
                    <GraphCard>
                      <h3>TICKET MÉDIO</h3>
                      <SepLine></SepLine>
                      <div className="chart">
                        <Line
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              datalabels: false,
                              legend: {
                                position: "bottom",
                                align: "center",
                              },
                              title: {
                                display: false,
                                text: "Total de oportunidades",
                              },
                            },
                          }}
                          data={{
                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],

                            datasets: [
                              {
                                type: "line",
                                label: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[0].nome
                                  : "",
                                fill: false,
                                data: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[0].valor
                                  : "",
                                borderColor: ["#68998a"],
                              },
                              {
                                type: "line",
                                label: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[1].nome
                                  : "",
                                fill: false,
                                data: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[1].valor
                                  : "",
                                borderColor: ["#459dd1"],
                              },
                              {
                                type: "line",
                                label: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[2].nome
                                  : "",
                                fill: false,
                                data: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[2].valor
                                  : "",
                                borderColor: ["#b04549"],
                              },
                              {
                                type: "line",
                                label: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[3].nome
                                  : "",
                                fill: false,
                                data: graficosPermitidos.includes(
                                  "TICKET MEDIO"
                                )
                                  ? ticketMedio[3].valor
                                  : "",
                                borderColor: ["#776b9c"],
                              },
                            ],
                          }}
                          width={800}
                          heigth={10}
                        />
                      </div>
                    </GraphCard>
                  ) : (
                    ""
                  )}
                </EstatiticasContainer>
                <EstatiticasContainer>
                  {graficosPermitidos.includes("RECEITA POR VENDEDOR") ? (
                    <GraphCard>
                      <h3>RECEITA POR VENDEDOR</h3>
                      <SepLine></SepLine>
                      <div className="chart">
                        <Bar
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            indexAxis: "x",
                            plugins: {
                              plugins: [ChartDataLabels],
                              datalabels: {
                                color: "white",
                                display: function (context) {
                                  return (
                                    context.dataset.data[context.dataIndex] > 15
                                  );
                                },
                                font: {
                                  weight: "bold",
                                },
                                formatter: function (value, context) {
                                  return value
                                    .toLocaleString("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    })
                                    .replace("R$", "");
                                },
                              },
                              title: {
                                display: false,
                                text: "Quantidade de rastreadores atualizados por tempo",
                              },
                              legend: {
                                position: "bottom",
                                align: "center",
                              },
                            },
                          }}
                          data={{
                            labels: [
                              graficosPermitidos.includes(
                                "RECEITA POR VENDEDOR"
                              )
                                ? receitaPorVendedor[0].nome
                                : "",
                              graficosPermitidos.includes(
                                "RECEITA POR VENDEDOR"
                              )
                                ? receitaPorVendedor[1].nome
                                : "",
                              graficosPermitidos.includes(
                                "RECEITA POR VENDEDOR"
                              )
                                ? receitaPorVendedor[2].nome
                                : "",
                              graficosPermitidos.includes(
                                "RECEITA POR VENDEDOR"
                              )
                                ? receitaPorVendedor[3].nome
                                : "",
                              graficosPermitidos.includes(
                                "RECEITA POR VENDEDOR"
                              )
                                ? receitaPorVendedor[4].nome
                                : "",
                            ],

                            datasets: [
                              {
                                label: "Valor em R$",
                                data: [
                                  graficosPermitidos.includes(
                                    "RECEITA POR VENDEDOR"
                                  )
                                    ? receitaPorVendedor[0].valor
                                    : "",
                                  graficosPermitidos.includes(
                                    "RECEITA POR VENDEDOR"
                                  )
                                    ? receitaPorVendedor[1].valor
                                    : "",
                                  graficosPermitidos.includes(
                                    "RECEITA POR VENDEDOR"
                                  )
                                    ? receitaPorVendedor[2].valor
                                    : "",
                                  graficosPermitidos.includes(
                                    "RECEITA POR VENDEDOR"
                                  )
                                    ? receitaPorVendedor[3].valor
                                    : "",
                                  graficosPermitidos.includes(
                                    "RECEITA POR VENDEDOR"
                                  )
                                    ? receitaPorVendedor[4].valor
                                    : "",
                                ],
                                backgroundColor: [
                                  "#459dd1",
                                  "#459dd1",
                                  "#459dd1",
                                  "#459dd1",
                                  "#459dd1",
                                ],
                                borderColor: [
                                  "#459dd1",
                                  "#459dd1",
                                  "#459dd1",
                                  "#459dd1",
                                  "#459dd1",
                                ],
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={900}
                          heigth={900}
                        />
                      </div>
                    </GraphCard>
                  ) : (
                    ""
                  )}
                </EstatiticasContainer>
              </BlocoEstatisticas2>
            </div> */}
          </Container>
        </>
      )}
    </>
  )
}

export default Inicio
