import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ModalCliente from './Modal/ModalCliente'
import { useNavigate } from 'react-router-dom'
import { FaPlus, FaFilter, FaSearch } from 'react-icons/fa'
import { BsFillFilterCircleFill } from 'react-icons/bs'
import { RiBarChartHorizontalFill } from 'react-icons/ri'
import { MdTableRows } from 'react-icons/md'
import DataTable from './Data Table/DataTable'
import Kanban from './Kanban/Kanban'
import {
  Container,
  SwitchBotoes,
  FunisBotoes,
} from './styles/styledOportunidades'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './styles/ClienteOp.css'
import Loading from '../../../Loading/Loading'
import { BtnSubmit } from './Modal/style/styledModal'
import { AuthContext } from '../../../../context/auth'
import FilterModal from './FilterModal/FilterModal'
import { CadastroVeiculo } from '../../../../context/CadastroVEIContext'
import { handleClose } from '../../../../services/handleClose'
import { oportunidadeRepository } from '../../../../api/repositories/oportunidadesRepository'
import { statusRepository } from '../../../../api/repositories/statusRepository'
import { TableData } from './Data Table/TableData'
import {
  CardTable,
  CardTableHeaderNoTitle,
  SearchWrapper,
  TableSearch,
  TableSearchButton,
  TableSearchContainer,
} from './Data Table/styles/styledDataTable'
import lupaicon from './Data Table/assets/icon-lupa.svg'
import adicionarOportunidade from '../../assets/icon-add.svg'
import mudarVisualizacaoOportunidade from '../../assets/icon-settings-fill.svg'
import { Dropdown } from 'react-bootstrap'

const Oportunidades = () => {
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tableView, setTableView] = useState(true)
  const [filterModal, setFilterModal] = useState(false)
  const [funis, setFunis] = useState([])
  const [funilNome, setFunilNome] = useState('')
  const [pesquisa, setPesquisa] = useState('')
  const [numeroPagina, setNumeroPagina] = useState(0)

  // const [funilAtual, setFunilAtual] = useState("");

  const navigate = useNavigate()

  const { PER_ID, FUN_ID, setFUN_ID } = useContext(AuthContext)
  const { setValorTotalImplementos, setImplementos } =
    useContext(CadastroVeiculo)
  const [filterStatus, setFilterStatus] = useState('TODAS')

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const VEN_ID = sessionStorage.getItem('VEN_ID')
  const FUN_NOME = sessionStorage.getItem('FUN_NOME')

  const MySwal = withReactContent(Swal)

  function checarStatus() {
    const verificarStatus = async () => {
      try {
        await statusRepository.verificarStatus()
        setOpenModal(true)
        setLoading(false)
        console.log('PASSOU POR AQUI')
      } catch (error) {
        console.log(error)
        setLoading(false)
        MySwal.fire({
          title: 'Atenção!',
          text: 'Serviço indisponível no momento. Por favor, tente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        }).then((res) => {
          if (res.isConfirmed) {
            navigate('/home/oportunidades')
          } else {
            // not clicked
          }
        })
      }
    }

    verificarStatus()

    /*
    axios
      .get(`https://kaminocrm.com.br:7778/status/verificarStatus/`, {
        headers: {
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setOpenModal(true)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        MySwal.fire({
          title: 'Atenção!',
          text: 'Serviço indisponível no momento. Por favor, tente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        }).then((res) => {
          if (res) {
            navigate('/home/oportunidades')
          } else {
            // not clicked
          }
        })
      })
    */
  }

  const funilAtual = sessionStorage.getItem('FUN_ID')

  function getFunNome(arr) {
    const fun = arr.find(function (funil) {
      return funil.value === FUN_ID
    })
    setFunilNome(fun?.label)
  }

  useEffect(() => {
    sessionStorage.setItem('FUN_ID', FUN_ID)
    if (!funilNome) {
      setFunilNome(FUN_NOME)
    } else {
      sessionStorage.setItem('FUN_NOME', funilNome)
    }
    // getFunNome();
  }, [FUN_ID])

  useEffect(() => {
    ;(async () => {
      setValorTotalImplementos(0)
      setImplementos([])
      handleClose()

      const listarFunisOportunidade = async () => {
        try {
          const data =
            await oportunidadeRepository.listarFunisOportunidade(VEN_ID)
          // console.log('DADOS DOS FUNIS OPORTUNIDADE PARAMETROS: ', data)
          setFunis(
            data.funis.map((e) => {
              return {
                value: e.FUN_ID,
                label: e.FUN_NOME,
              }
            }),
          )
          if (funilAtual == '' || !funilAtual) {
            setFUN_ID(data.funis[0].FUN_ID)
          } else if (FUN_ID == '') {
            setFUN_ID(funilAtual)
          }
          getFunNome(funis)
          if (!funilNome) {
            setFunilNome(FUN_NOME)
          }
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }

      listarFunisOportunidade()

      /*
      await axios
        .get(
          `https://kaminocrm.com.br:7778/oportunidades/listarFunisOportunidade?VEN_ID=${VEN_ID}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          },
        )
        .then((res) => {
          setFunis(
            res.data.funis.map((e) => {
              return {
                value: e.FUN_ID,
                label: e.FUN_NOME,
              }
            }),
          )
          if (funilAtual == '' || !funilAtual) {
            setFUN_ID(res.data.funis[0].FUN_ID)
          } else if (FUN_ID == '') {
            setFUN_ID(funilAtual)
          }
          getFunNome(funis)
          if (!funilNome) {
            setFunilNome(FUN_NOME)
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
      */
    })()
  }, [])

  const OptionsButton = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          // id={'options'}
          // // @ts-ignore
          // variant="borderless-dark"
          // bsPrefix="no-chevron"
          // size="sm"
          disabled={openModal ? true : false}
          // onClick={() => console.log(i)}
          className="dropdownToggle_OptionsMenu"
        >
          <div className="circle">
            <img src={mudarVisualizacaoOportunidade} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ willChange: 'transform' }}>
          {/* <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <span>Modo de visualização</span>
          </div>
          <Dropdown.Divider /> */}
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
          >
            <button
              className={tableView ? 'active' : ''}
              style={{ width: '100%' }}
              onClick={() => {
                setTableView(true)
              }}
            >
              <span style={{ display: 'flex', width: '100%', gap: '0.5rem' }}>
                <RiBarChartHorizontalFill
                  size={15}
                  style={{ transform: 'rotate(90deg)' }}
                />
                Kanban
              </span>
            </button>

            <button
              style={{ width: '100%' }}
              className={!tableView ? 'active' : ''}
              onClick={() => {
                setTableView(false)
              }}
            >
              <span style={{ display: 'flex', width: '100%', gap: '0.5rem' }}>
                <MdTableRows size={15} />
                Tabela
              </span>
            </button>

            <FunisBotoes>
              <div className="container">
                <button
                  className="btn_filter"
                  onClick={() => setFilterModal(true)}
                >
                  <BsFillFilterCircleFill size={15} />
                </button>
                <span onClick={() => setFilterModal(true)}>
                  {funilNome ? funilNome : 'PADRÃO'}
                </span>
                <span onClick={() => setFilterModal(true)}>
                  {filterStatus === 'TODAS' || filterStatus === 'CONVERTENDO'
                    ? filterStatus
                    : `${filterStatus}S`}
                </span>
              </div>
            </FunisBotoes>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <>
      {loading && <Loading />}
      {openModal && <ModalCliente closeModal={setOpenModal} />}

      <Container>
        {filterModal && (
          <FilterModal
            setFilterModal={setFilterModal}
            funis={funis}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            setFunilNome={setFunilNome}
          />
        )}

        <header>
          <div>
            <SwitchBotoes>
              <h2 className="Title__Opo">Oportunidades</h2>
              {PER_ID == 0 ? (
                <>
                  <img
                    src={adicionarOportunidade}
                    className="addOportunity"
                    to="cadastro-cliente"
                    onClick={() => {
                      checarStatus()
                      setLoading(true)
                    }}
                  />

                  <OptionsButton />
                </>
              ) : (
                <OptionsButton />
              )}
            </SwitchBotoes>

            <div>
              <span>
                Adicione novas oportunidades ou pesquise e gerencie as
                existentes.
              </span>
            </div>
          </div>
          <div className="searchBoxTable">
            {tableView ? null : (
              <TableSearchContainer>
                <img src={lupaicon} />
                <TableSearch
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Pesquisar"
                  value={pesquisa}
                  onChange={(e) => {
                    setPesquisa(e.target.value)
                    setNumeroPagina(0)
                  }}
                />
              </TableSearchContainer>
            )}
          </div>
        </header>

        {tableView ? (
          <Kanban FUN_ID={FUN_ID} filterStatus={filterStatus} />
        ) : (
          <TableData
            FUN_ID={FUN_ID}
            filterStatus={filterStatus}
            pesquisa={pesquisa}
            numeroPagina={numeroPagina}
            setNumeroPagina={setNumeroPagina}
          />
        )}
      </Container>
    </>
  )
}

export default Oportunidades
