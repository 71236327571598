import {
  Avatar,
  BackgroundModal,
  CloseButton,
  Container,
  ContentModal,
  FormCol,
  FormField,
  FormRow,
  FormStep,
  Header,
  Label,
  Subtitle,
  Title,
  ListboxWrapper,
} from './styles'
import ImgClose from '../../../../Home/assets/icon-close.svg'
import ImgIconUser from '../../../../Home/assets/icon-usr-register-modal.svg'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { Listbox } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { vendedorRepository } from '../../../../../api/repositories/vendedorRepository'
import { userRepository } from '../../../../../api/repositories/userRepository'
import { Alerts } from '../../../../../utils/alert'

const PERFIL_ACESSO = [
  { perfilId: 0, value: 'Vendedor' },
  { perfilId: 1, value: 'Admin' },
]

export function ModalUser({ setOpenModal, openModal, setAlteracaoFeita }) {
  const [selectedPerfil, setSelectedPerfil] = useState('')
  const [selectedVen, setSelectedVen] = useState('')
  const [ven, setVen] = useState([])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm()

  useEffect(() => {
    if (selectedPerfil !== 'Vendedor') {
      setValue('venId', null)
    }
  }, [selectedPerfil, setValue])

  useEffect(() => {
    buscarVendedores()
  }, [])

  async function onSubmit(dataSubmiting) {
    console.log('onSubmit', dataSubmiting)

    const validantionData = {
      USR_NOME: dataSubmiting.nomeUser,
      USR_LOGIN: dataSubmiting.loginUser,
      USR_SENHA: dataSubmiting.senhaUser,
      PER_ID: String(dataSubmiting.perId),
      USR_MUDAR_SENHA: dataSubmiting.usrMudarSenha,
      VEN_ID: dataSubmiting.venId,
    }

    try {
      const data = await userRepository.cadastrarUsuario(validantionData)

      if (data.error) {
        Alerts.ERROR(data.message || 'Erro inesperado ao cadastrar usuário!')
        return
      }

      Alerts.SUCCESS('Usuário cadastrado com sucesso!')
    } catch (error) {
      console.log('Erro inesperado:', error.message)
      Alerts.ERROR('Erro inesperado ao cadastrar usuário!')
    }

    setOpenModal(false)
    setAlteracaoFeita(true)
  }

  function handleSelectPerfil(perfil) {
    setSelectedPerfil(perfil.value)
    setValue('perId', perfil.perfilId)
  }

  function handleSelectVen(ven) {
    setSelectedVen(ven.label)
    console.log(ven.label)
    setValue('venId', ven.value)
  }

  async function buscarVendedores() {
    try {
      const data = await vendedorRepository.buscarVendedor()

      const dataSelect = data.map((el) => {
        return {
          value: el.id,
          label: el.nome,
        }
      })

      setVen(dataSelect)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <CloseButton onClick={() => setOpenModal(false)}>
                <img src={ImgClose} alt="Fechar modal" />
              </CloseButton>
              <Avatar src={ImgIconUser} alt="Avatar" />
              <div>
                <Title>NOME DO USUARIO</Title>
                <Subtitle>E-mail</Subtitle>
              </div>
            </Header>
            <main>
              <FormStep>
                <FormRow>
                  <FormField>
                    <Label htmlFor="userName">Nome do Usuário</Label>
                    <input
                      type="text"
                      id="userName"
                      placeholder="EX.: Artur Bezerra"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                      {...register('nomeUser')}
                    />
                  </FormField>
                </FormRow>
                <FormCol>
                  <FormField>
                    <Label htmlFor="userLogin">Login</Label>
                    <input
                      type="text"
                      id="userLogin"
                      placeholder="Ex.: ARTUR"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                      {...register('loginUser')}
                    />
                  </FormField>
                  <FormField>
                    <Label>Perfil de acesso</Label>
                    <input
                      type="hidden"
                      {...register('perId')}
                      value={selectedPerfil}
                    />
                    <ListboxWrapper>
                      <Listbox
                        value={selectedPerfil}
                        onChange={handleSelectPerfil}
                      >
                        {({ open }) => (
                          // <div style={{ height: '100%' }}>
                          <>
                            <Listbox.Button
                              className="ListboxButton"
                              style={{ height: '100%', textAlign: 'left' }}
                            >
                              {selectedPerfil === ''
                                ? 'Selecione o perfil'
                                : selectedPerfil}
                            </Listbox.Button>
                            <Listbox.Options className="ListboxOptions">
                              {PERFIL_ACESSO.map((perfil) => (
                                <Listbox.Option
                                  key={perfil.perfilId}
                                  value={perfil}
                                  className="ListboxOption"
                                >
                                  {perfil.value}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </>
                          // </div>
                        )}
                      </Listbox>
                    </ListboxWrapper>
                  </FormField>
                </FormCol>
                <FormCol>
                  <FormField>
                    <Label htmlFor="password">Senha</Label>
                    <input
                      type="password"
                      placeholder="Informe a senha"
                      {...register('senhaUser')}
                    />
                  </FormField>
                  <FormField>
                    <Label htmlFor="password">Repita a Senha</Label>
                    <input type="password" placeholder="Repita a Senha" />
                  </FormField>
                </FormCol>
              </FormStep>
              <input type="hidden" {...register('venId')} value={selectedVen} />
              {selectedPerfil === 'Vendedor' ? (
                <FormStep>
                  <ListboxWrapper>
                    <Listbox value={selectedVen} onChange={handleSelectVen}>
                      <Listbox.Button className="ListboxButton">
                        {selectedVen === ''
                          ? 'Selecione o vendedor'
                          : selectedVen}
                      </Listbox.Button>
                      <Listbox.Options className="ListboxOptions">
                        {ven.map((item) => (
                          <Listbox.Option
                            key={item.value}
                            value={item}
                            className="ListboxOption"
                          >
                            {item.label}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Listbox>
                  </ListboxWrapper>
                </FormStep>
              ) : null}
              <FormStep>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch />}
                      label="Mudar senha após primeiro login"
                      {...register('usrMudarSenha')}
                    />
                  </FormGroup>
                </div>
              </FormStep>
              <div className="save_button">
                <button type="submit" disabled={isSubmitting}>
                  Salvar
                </button>
              </div>
            </main>
          </form>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
