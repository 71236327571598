import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const SectionTableContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 65vh;
`

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  position: sticky;
`

export const MainTableContainer = styled.main`
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 5px;
  }
`

export const TableHeader = styled.thead`
  line-height: 2rem;

  th {
    color: #838387;

    text-align: inherit;
    font-weight: normal;
    font-size: 0.9375rem;

    border-bottom: 0.2px solid #e2e2e2;

    background-color: #fff;

    position: sticky;
    position: -webkit-sticky;

    top: 0;
    z-index: 2;
    box-sizing: border-box;
    padding-left: 2rem;
  }

  th:first-child {
    min-width: 80px;
    padding-left: 0;
  }

  /* Status */
  th:nth-child(8) {
    text-align: center;
    padding-left: 0;
  }

  th:last-child {
    padding-left: 2rem;
  }
`

export const TableBody = styled.tbody`
  tr {
    line-height: 3.1rem;
    font-size: 0.92rem;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  }

  tr:hover {
    background-color: #f2f2f2;
  }

  tr td {
    color: #000;
    font-weight: 500;
    white-space: nowrap;

    text-overflow: ellipsis; /* Adiciona "..." ao final de textos longos */
    vertical-align: middle;
    box-sizing: border-box;
    padding-left: 2rem;
  }

  tr td:first-child {
    min-width: 80px;
    overflow-x: hidden;
    padding-left: 0;
  }

  /* Nome do Contato */
  tr td:nth-child(2) {
    min-width: 250px;
  }

  /* Placa */
  tr td:nth-child(3) {
    max-width: 100px;
    min-width: 100px;
    overflow-x: hidden;
  }

  /* Data de criação */
  tr td:nth-child(4) {
    min-width: 130px;
    overflow-x: hidden;
  }

  /* Telefone */
  tr td:nth-child(5) {
    min-width: 130px;
    overflow-x: hidden;
  }

  /* E-mail */
  tr td:nth-child(6) {
    min-width: 200px;
  }

  /* Consultor Responsável */
  tr td:nth-child(7) {
    min-width: 200px;
  }

  tr td:nth-child(8) {
    padding-left: 0;
  }

  tr td:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 2rem;
  }

  td > div {
    display: flex;
    justify-content: center;
  }
`

export const SpanStatusTable = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 9px;
  font-weight: bold;
  text-align: center;

  border-radius: 9px;

  line-height: 1rem;
  width: 4.75rem;

  height: 18px;
`
/* DROPDOWN */

// export const DropdownMenuContainer = styled(Dropdown.Menu)`
//   z-index: 2;
//   position: absolute;
//   left: -7.25rem;
//   top: 3.5rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 0.9375rem;
//   border-radius: 10px;
//   min-width: 150px;
//   background: #fff;
//   box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);
//   padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;

//   button,
//   a {
//     border: none;
//     background: none;
//   }
// `
