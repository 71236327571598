import styled from 'styled-components'
import { COLORS } from '../../../../../../colors.js'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  margin: 0 6rem;

  @media only screen and (max-width: 1400px) {
    margin: 0 4rem;
    /* width: 100%;
    overflow-x: auto; */
  }
  @media only screen and (max-width: 1399px) {
    margin: 0;
    /* width: 100%;
    overflow-x: auto; */
  }
`

export const KanbanWrapper = styled.div`
  height: 76vh;
  max-height: 76vh;

  @media only screen and (max-width: 450px) {
    height: 59vh;
    max-height: 59vh;
  }

  .container {
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow: auto;
    flex: 1 1 0%;
    position: relative;

    ::-webkit-scrollbar {
      width: 1px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }

    ::-webkit-scrollbar-thumb {
      background: #ddd;
      border: 4px solid #fff;
    }
  }

  .columns {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    // align-items: stretch;
    user-select: none;
    /* padding: 0px 0px 40px 20px; */
    width: fit-content;
  }

  .wrapper {
    display: flex;
    gap: 0.45rem;
    // flex: 1 1 auto;

    @media only screen and (max-width: 450px) {
      padding-bottom: 0;
    }
  }

  .column {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-self: auto;
    flex: 1 1 auto;
    min-width: 19.5rem;
    max-width: 19.5rem;
    border-radius: 7px;
    max-height: 41.5rem;

    @media only screen and (max-width: 450px) {
      height: 22rem;
      min-height: 22rem;
    }

    @media only screen and (max-height: 720px) {
      max-height: 31.5rem;
    }

    .btn__order {
      border: none;
      /* background-color: red; */
      padding: 0;
      border-radius: 5px;
      transition: 0.2s;
      :hover {
        filter: brightness(90%);
      }
    }

    .top {
      background-color: #f2f2f2;
      padding: 10px 15px;
      border-radius: 15px 15px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        .cor_categoria {
          height: 9px;
          width: 9px;
          border-radius: 10px;
          margin-right: 0.5rem;
        }
        .nome_categoria {
          font-weight: 600;
          color: #31303f;
          text-transform: uppercase;
        }
      }
    }

    .header_values {
      border-bottom: 1px solid #e0e0e0;
      padding: 0 15px 10px 15px;
      color: ${COLORS.primary};
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 13px;
    }

    .buttonDownItens {
      display: flex;
      width: 100%;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      color: #534545;

      :hover {
        color: #000000;
      }
    }
  }

  .card_container {
    width: auto;
    padding: 15px;
    /* background-color: red; */
    // -> min-height: 99%;
    /* height: 35rem; */
    max-height: 99%;
    overflow: hidden;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 450px) {
      padding: 0 15px;
      min-height: 18rem;
      height: 18rem;
      max-height: 18rem;
      overflow-y: auto;
    }

    .btn-wrapper {
      position: relative;
      height: auto;
      width: auto;
      z-index: 1;

      .options-btn {
        background-color: #fff;
        display: flex;
        border: none;
        padding: 0;
        margin: 0;
        text-align: center;
        align-items: center;
        color: #9b9aad;
        :hover {
          color: #83869e;
          cursor: pointer;
        }
      }
    }

    #convertida {
      border-left: 5px solid ${COLORS.convertida};
    }
    #perdida {
      border-left: 5px solid ${COLORS.perdida};
    }
    #convertendo {
      border-left: 5px solid ${COLORS.vistoria};
    }
    #incompleto {
      border-left: 5px solid orange;
    }

    .card {
      width: auto;
      height: 9.7em;
      background-color: #fff;
      border-radius: 5px;
      padding: 20px;
      border: 1px solid #dbe1e9bf;
      box-shadow: 0px 1px 0px #9796d040;
      margin-bottom: 5px;
      position: relative;

      :hover {
        cursor: pointer;
      }

      .item {
        display: block;
        margin-bottom: 6px;
        color: #373f59;
        text-overflow: ellipsis;
        white-space: nowrap;
        inline-size: 100%;
        overflow: hidden;
      }

      #nome,
      #tarefa {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #31303f;
        font-weight: 600;
        font-size: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 9px;

        .nome_span {
          margin: 0;
          inline-size: 100%;
          overflow: hidden;
        }
      }

      #modelo {
        font-size: 13px;
        color: #565c70;
      }

      .bottom {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        bottom: 27px;
        left: 0;
        height: 15%;
        width: 100%;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
        /* margin-top: 10px; */
        gap: 0.3rem;

        .under-bottom {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 0.45rem;

          .icons {
            display: flex;
            gap: 0.3rem;
            margin: 0 20px;
          }
        }

        #data-criacao {
          color: #565c70;
          margin-left: 20px;
          font-size: 12px;
          font-weight: 500;
        }

        #agendado {
          margin-left: 20px;
          color: #fff;

          .icone_agendado {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 21px;
            background-color: #4c8ced;
            border-radius: 50px;
          }
        }
        #origem {
          display: flex;
          align-items: center;
          margin-bottom: 0.6rem;
          color: #515c83;
        }

        #vendedor,
        #tarefa {
          display: flex;
          align-items: center;
          color: #515c83;
        }

        #vendedor {
          margin-left: 20px;
        }
      }
    }
  }
`
