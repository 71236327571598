import { useEffect, useState } from 'react'
import ImgAdd from '../../../../Home/assets/icon-add.svg'
import menuAjustesTable from '../../../../Home/assets/menu-ajustes-table.svg'
import { MensalidadeContainer } from './styles'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { NovaMensalidade } from './NovaMensalidade/NovaMensalidade'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import editPencil from '../../../../Home/assets/icon-edit-pencil.svg'
import archiveIcon from '../../../../Home/assets/icon-arquivar.svg'
import { ModalArchive } from './ModalArchive/ModalArchive'

export function Mensalidade({ setLoading }) {
  const [filterResult, setFilterResult] = useState([])
  const [dataList, setDataList] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [alteracaoFeita, setAlteracaoFeita] = useState(false)
  const [menId, setMenId] = useState(null)
  const [modalArchiveMensalidade, setModalArchiveMensalidade] = useState(false)
  const [mensalidade, setMensalidade] = useState([])

  useEffect(() => {
    setLoading(true)
    listarTabelaMensalidade()
    setAlteracaoFeita(false)
  }, [alteracaoFeita])

  async function listarTabelaMensalidade() {
    try {
      const data = await parametrosRepository.listarTabelaMensalidade()
      setDataList(data?.dados)
      setFilterResult(data?.dados)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = dataList.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })

    setFilterResult(filteredData)
  }

  function ButtonDropDownMenu({ mensalidade }) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '5rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!mensalidade.MEN_ATIVO}
              onClick={() => {
                setMenId(mensalidade.MEN_ID)
                setOpenModal(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setModalArchiveMensalidade(true)
                setMensalidade(mensalidade)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>
                  {mensalidade.MEN_ATIVO ? 'Arquivar' : 'Desarquivar'}
                </span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <MensalidadeContainer>
      <>
        {openModal && (
          <NovaMensalidade
            openModal={openModal}
            setOpenModal={setOpenModal}
            mensalidadeId={menId}
            alteracaoFeita={setAlteracaoFeita}
            setLoading={setLoading}
          />
        )}

        {modalArchiveMensalidade && (
          <ModalArchive
            openModal={modalArchiveMensalidade}
            setOpenModal={setModalArchiveMensalidade}
            alteracaoFeita={setAlteracaoFeita}
            mensalidadeData={mensalidade}
          />
        )}

        <section>
          <header>
            <div>
              <div className="SettingsUsers_Header_Title">
                <span className="SettingsUsers_Header_Title">Mensalidade</span>
                <img
                  src={ImgAdd}
                  onClick={() => {
                    setMenId(null)
                    setOpenModal(true)
                  }}
                />
              </div>
              <div>
                <span className="SettingsUsers_Header_SubTitle">
                  Gerencie ou crie descontos de mensalidade
                </span>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => event.preventDefault()}>
                <input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={searchInput}
                  onChange={handleChangeFilter}
                  placeholder="Pesquisar"
                  maxLength="25"
                />
              </form>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>% (Máx.)</th>
                  <th>Tipo de Veículo</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filterResult.map((item) => (
                  <tr>
                    <td>{item.MEN_NOME.toUpperCase()}</td>
                    <td>{item.MEN_PORCENTAGEM}%</td>
                    <td>{item.MEN_TIPO_VEICULO}</td>
                    <td>
                      <div
                        className={`SettingsUsers__Status ${
                          item.MEN_ATIVO
                            ? 'SettingsUsers__Status--active'
                            : 'SettingsUsers__Status--inactive'
                        }`}
                      >
                        {item.MEN_ATIVO ? 'ATIVO' : 'ARQUIVADO'}
                      </div>
                    </td>
                    <td>
                      <ButtonDropDownMenu mensalidade={item} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    </MensalidadeContainer>
  )
}
