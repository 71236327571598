import { BackgroundModal, Container, ContentModal } from './styles'
import IconAlert from '../../../../../Home/assets/alerta.svg'
import { parametrosRepository } from '../../../../../../api/repositories/parametrosRepository'

export function ModalArchive({
  openModal,
  setOpenModal,
  alteracaoFeita,
  filiacaoData,
}) {
  async function handleArquivar() {
    try {
      await parametrosRepository.arquivarOuDesarquivarTabelaFiliacao(
        filiacaoData.FIL_ID,
      )

      setOpenModal(false)
      alteracaoFeita(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <div className="SettingsUsersModal__Archive--Container">
            <img src={IconAlert} alt="icone de alerta" />
            <h1 className="SettingsUsersModal__Archive--Title">
              {filiacaoData.FIL_ATIVO ? 'ARQUIVAR' : 'DESARQUIVAR'} REGISTRO?
            </h1>
            <p className="SettingsUsersModal__Archive--Subtitle">
              <span>
                O registro {filiacaoData.FIL_ATIVO ? 'não' : ''} poderá ser
                utilizado após
                {filiacaoData.FIL_ATIVO ? ' arquivado' : ' desarquivado'}!
              </span>
              <span>
                Tem certeza que deseja{' '}
                {filiacaoData.FIL_ATIVO ? 'arquivar' : 'desarquivar'}?
              </span>
            </p>
            <div className="SettingsUsersModal__Archive--Buttons">
              <button
                style={{ border: 'none' }}
                onClick={() => setOpenModal(false)}
              >
                NÃO
              </button>
              <button style={{ border: 'none' }} onClick={handleArquivar}>
                SIM
              </button>
            </div>
          </div>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
